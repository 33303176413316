const initialState=[]

const Quantreducer =(quantityList=initialState,action)=>{
    switch(action.type){
        case 'quantity-added' : {
            // console.log(action.payload)
            
            return [
                ...quantityList,
                {
                    ...action.payload,
                    
                }
            ]
        }
        case 'quantity-reduced' : {   
                    
            return [
                ...quantityList,
                {
                    ...action.payload,
                   
                }
            ]
        }

        default:
            return quantityList

    }
}

export default Quantreducer;