// import { useCookies } from "react-cookie";
import instance from "../Url";
import { getCartlist } from "./cart.action";
const initialState=[]

const Cartreducer=(cartList=initialState,action)=>{
    // const [cookies, setCookie] = useCookies(['token', '_cid', '_wid', 'user']);
    switch(action.type){
        case 'cartitem-added': {  
            return [
                ...cartList,
                {
                    ...action.payload
                }
            ]                    
        }
        case 'cartitem-deleted' : {
            
            let actObj = {...action.payload};
            let array = [...cartList];
            array = array.filter(el => el._id !== actObj._id);
            array.forEach(el => {
                el.isAdded = 1
            })
            return array
        }
        case 'cartitem-quantity-increase' : {
            let actObj = {...action.payload};
            let array = [...cartList];
            array.forEach(el => {
                if (el._id === actObj._id) {
                    el.quantity = actObj.quantity
                }
            })
            return array
        }
        case 'cartitem-quantity-decrease' : {
            let actObj = {...action.payload};
            let array = [...cartList];
            array.forEach(el => {
                if (el._id === actObj._id) {
                    el.quantity = actObj.quantity
                }
            })
            return array
        }
        case 'load-cartdata': {
            let arr = [...action.payload];
            arr = arr.filter((el, index, self) => index === self.findIndex((t) => (
                t._id === el._id
            )))
            console.log('duplicate', arr);
            return arr;
        }
        case 'clearCart' :{
            
            return cartList=[];
        }

        case 'cartdata-by-user':{
            let arr = [...action.payload];
            // console.log(arr)
             return [
                   ...arr
             ]
        }
        // case 'fetch-cartlist-byuser':{
        //   getCartlist();
        // }

        case 'update-cart-list':{
            // let arr = [...action.payload];
            // console.log(arr)
             return [
                   ...cartList
             ]
        }
        default:
            return cartList
    }
}

export default Cartreducer;