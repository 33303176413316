let initialState = {}

export default function deliverySlot(state = initialState, action) {
    console.log(action)
    switch (action.type) {
        case 'add_delivery_slot':
            return { ...action.payload }

        default:
            return state
    }
}

// export default deliverySlot