import axios from "axios";

const instance = axios.create({
  // baseURL:'https://fathomless-shelf-75146.herokuapp.com/api/',
  // baseURL:'https://aonemart-api.azurewebsites.net/api/',
  // baseURL: 'https://aone-test-api.azurewebsites.net/api/',
  // baseURL: 'https://daily-mandi-api.azurewebsites.net/api/'
  baseURL: 'https://api.dailymandi.com/api/'
  // baseURL: "http://149.202.139.245:3002/api/",
  // baseURL: "http://23.227.177.172:3002/api/",
  // baseURL: "https://dailymandiapi.indwaredigital.com/api/",
});

export default instance;
