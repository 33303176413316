import instance from '../Url';


export const getTax = () => async (dispatch, getState) => {
    // await instance.get('tax/taxes').then(res => {
    //     // console.log('tax data', res.data)
    //     const value = res.data.data[0];
    //     dispatch({ 
    //         type: 'taxValues', 
    //         payload: { 
    //             taxPercentage: value.taxPercentage, 
    //             appliedOn: value.appliedOn, 
    //             shippingCharge: value.shippingCharge, 
    //             shippingAppliedOn: value.shippingAppliedOn,
    //             minOrderValue: value.minOrderValue,
    //         } 
    //     })
    // })
}