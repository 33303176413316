import React from 'react';

const Linklist=()=>{
    return (
        <>
     <div className="icon-bar">
        <a href="!#" className="facebook" title="Share"><i className="fab fa-facebook-f"></i></a>
        <a href="!#" className="google" title="Share"><i className="fab fa-google"></i></a>
        <a href="!#" className="whatsapp" title="Share"><i className="fab fa-whatsapp"></i></a>
     </div>
        </>
    );
}

export default Linklist;