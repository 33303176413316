const initialState = {
    offCanvas: null, // 1 for category offcanvas, 2 for cart offcanvas, 3 for wishlist offcanvas, 4 for filter offcanvas,
    category: [],
    products: [],
    filters: [],
    tax: 0,
    taxOn: 0
}

export default function globalVariablesReducer(state = initialState, action) {
    switch (action.type) {
        
        case 'close-filter-sider' :
            return {
                ...state,
                offCanvas: 4
            }  
        case 'category-for-filter': 
            return {
                ...state, 
                category: action.payload
            }
        case 'set-filtered-products':
            return {
                ...state,
                products: action.payload
            }
        case 'applied-filters': 
            return {
                ...state,
                filters: action.payload
            }
        case 'taxValues': 
            return {
                ...state,
                tax: action.payload.taxPercentage,
                taxOn: action.payload.appliedOn,
                shipping: action.payload.shippingCharge,
                shippingOn: action.payload.shippingAppliedOn,
                minOrder: action.payload.minOrderValue
            }
        default: 
            return state
    }
}