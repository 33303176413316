export const loggerMiddleware = storeAPI => next => action => {
  // console.log('dispatching', action)
  // if (action.type === 'cartitem-added') {
  //     localStorage.setItem('cart', JSON.stringify(action.payload) )
  // }
  let result = next(action)

  let cartState = storeAPI.getState()

  // console.log('next state', cartState)
  sessionStorage.setItem('hub_data', JSON.stringify(cartState.pin_hub));
  sessionStorage.setItem('cart1', JSON.stringify(cartState.cart))
  sessionStorage.setItem('wish1', JSON.stringify(cartState.wish))
  return result
}