let initialState = {}

export default function cartState(state = initialState, action) {
    switch (action.type) {
        case 'remove_cart_item':
            return { ...action.payload }

        default:
            return state
    }
}