export default function pin_hub_data(
    initialState = {
        pincode: '',
        hub_id:{
            id:'',
            name:'',
            address:''
        }
    },
    action
) {


    switch (action.type) {

        case 'set_user_pin_hub': {
            let obj = { ...initialState , pincode:action.payload.pincode ,hub_id:{

                id:action.payload.hub_id.id,
                name:action.payload.hub_id.name,
                address:action.payload.hub_id.address
            }}

            // console.log(obj ,"payload data.")
            return obj
            // console.log(action.payload)
            // console.log(obj)
        }
        
        case 'empty_hub' :{
        
            let obj = { ...initialState , pincode:action.payload.pincode ,hub_id:{

                id:action.payload.hub_id.id,
                name:action.payload.hub_id.name,
                address:action.payload.hub_id.address
            }}
            return obj
        }

        default:
            return initialState
    }
}