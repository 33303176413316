import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import instance from './Url';
import { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { useSelector } from 'react-redux';
// import { NavLink } from "react-router-dom";

import { Spinner } from 'react-bootstrap';



const hub_details = (state) => {
  return state.pin_hub
}


function SelectModal(props) {
  const history = useHistory();
  const [list, setList] = useState([]);
  const [subToggle, setSubtoggle] = useState(false);
  const [subList, setSubList] = useState([]);
  const [catList, setCatList] = useState([{ _id: '', coverImage: '', categoryName: '' }]);
  const hub_data = useSelector(hub_details)
 const [catId,setCatId] = useState('')
  const [loading, setLoading] = useState(false);

  const getUrl = 'category/get-Category-list';

  useEffect(() => {
    // fetchData();
    getcatList()

  }, []);

  useEffect(() => {
    console.log(window.location.href)
    let _s = window.location.href.split('/')
    var urlSearchParams = new URLSearchParams(window.location.search);
    var specificParamValue = urlSearchParams.get('id');

    // let _s_id = _s?.split('=')
    // console.log(specificParamValue)
    setCatId(specificParamValue)
  }, [window.location.href])
  const getcatList = () => {

    setLoading(true);
    instance.get(`categories/web/list/`)
      .then(async (req) => {
        if (req.status === 200) {
          let res_data = await req.data.data
          // console.log(res_data)
          setCatList(res_data);
          setLoading(false);
        } else {
          setCatList([])
        }
      }).catch((err) => {
        // if (err.response.status === 401) {
        setCatList([])
        // }
      })

  };

  const getsubCat = (id) => {
    // console.log(id);
    instance.get(`/category/get-subCategory-list/${id}`)
      .then(req2 => {
        // console.log(req2.data.data);
        setSubList(req2.data.data);
        setSubtoggle(true);
      })

  };

  const backToMainMenu = () => {
    setSubtoggle(false);

  }

  const goToProduct = (data) => {
    // console.log(data)
    let _data_obj = {
      _id:data._id
    }
    history.push({
      pathname: '/product-list',
      state: { productType: 'subcategory', name:data.categoryName, pData:data._id, hub_id: hub_data.hub_id.id },
      search: `?id=${data._id}&name=${data.categoryName}`
    });
    props.handleClose();

  }

  const newproductToprList = () => {
    // hideNav()
    history.push({
      pathname: '/product-list',
      state: {
        productType: 'new', name: 'New-Added-Products'
      }
    })
    props.handleClose();
  };

  const bestSellproductToPrList = () => {
    // hideNav()
    history.push({
      pathname: '/product-list',
      state: {
        productType: 'best', name: 'Best-Selling-Products'
      }
    });
    props.handleClose();
  };


  return (
    <div>

      {/* <div className="main-menu" onClick={ backToMainMenu }>
                            <i className="fa fa-arrow-left"></i> Main-Menu
                        </div> */}
      <ul className="category-list">

        <li className="category-list-items  " onClick={() => props.handleClose()}>
          <NavLink exact to='/' className="main_text_color" title="Home">
            Home
          </NavLink>
        </li>
        <li className="category-list-items " onClick={newproductToprList}>
          <span className='main_text_color'>New Products</span>
        </li>
        <li className="category-list-items main_text_color" onClick={bestSellproductToPrList}>
          <span className='main_text_color'> Bestseller</span>

        </li>
        <p className='category_name mb-2'>Categories</p>
        <div className='line2'></div>
        {/* <p className='border border-dark'></p> */}

        <div className="smenu">
          {

            loading === true ?
              <div className='d-flex justify-content-center align-items-sm-center align-items-lg-center'>
                <div>
                  <Spinner animation="border" variant="success" />
                  <br />
                  <p>Loading.</p>
                </div>
              </div>
              :
              catList.filter(item => {
                return item.isActive === '1'
              })
                .map(item => (
                  <Fade top>
                    <li className= {item._id === catId ? 'category-list-items-active' :"category-list-items" } key={item._id} onClick={() => goToProduct(item)}>
                      {/* <img src={item.coverImage} alt="offers Banner" /> */}
                      
                      {item.categoryName} <i className="" style={{ float: 'right' }}></i>

                    </li>
                  </Fade>
                ))}
        </div>



      </ul>
      {/* </div> }                   */}
      {/* <a href="!#" className="morecate-btn"><i className="uil uil-apps"></i>More Categories</a> */}

    </div>
  );
}

export default SelectModal;