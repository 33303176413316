let initialState = {}

export default function RequirereduxState(state = initialState, action) {
    switch (action.type) {
        case 'remove_wishitem':
            return {...action.payload}
            // console.log(action.payload)
        default:
            return state

    }
}