const iniWishlist = []

const WishlistSlice = (wishlist = iniWishlist, action) => {
    switch (action.type) {
        case 'added-to-wishlist':
            return [
                ...wishlist,
                {
                    ...action.payload

                }
            ];
        case 'remove_wishList':
            console.log(...wishlist, 'fdfdfdfd')
        // let remove_obj = { ...action.payload }
        // return remove_obj;
        // console.log(remove_obj, '..........')
        // let actObj = { ...action.payload };
        // let array = [...wishlist];
        // array = array.filter(el => el._id !== actObj._id);
        // array.forEach(el => {
        //     el.isFav = 1
        // })
        // return array
        case 'load-wishdata': {
            let arr = [...action.payload];
            arr = arr.filter((el, index, self) => index === self.findIndex((t) => (
                t._id === el._id
            )))
            console.log('duplicate', arr);
            return arr;
        }

        case 'get-wishlist': {
            let arr = [...action.payload]
            return [...arr]
        }
        case 'clear-wishlist': {
            return wishlist = []
        }
        default:
            return wishlist;
    }
};

export default WishlistSlice;