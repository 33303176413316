import CookieConsent from "react-cookie-consent";
import { BrowserRouter } from "react-router-dom";
// import { getToken } from 'firebase/messaging';
// import { messaging } from './notification_init_somart';
import MainRoute from "./components/Navigation/MainRoute";
import "./App.css";
import { useEffect } from "react";

function App() {
  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if (permission === 'granted') {
  //     // Generate Token
  //     const token = await getToken(messaging, {
  //       vapidKey: 'BK1X5w2U9VhFCShUWXj7UMxFc93Hpebt5ClfRKSbl8-NCseBdNb22FGrGpZ9Mv23MOJC2Xv1J2IGLDZlDeacBZ4',
  //     });
  //     console.log('Token Gen', token);
  //     // Send this token  to server ( db)
  //   } else if (permission === 'denied') {
  //     alert('You denied for the notification');
  //   }
  // }

  const widthChange = () => {
    if (window.innerWidth < 480) {
      let menuitemMain = document.getElementById("footer-bar");
      let menuLink = menuitemMain.getElementsByTagName("a");

      for (var i = 0; i < menuLink.length; i++) {
        menuLink[i].addEventListener("click", function () {
          console.log(menuLink[0]);
          var current = document.getElementsByClassName("active-nav");
          current[0].className = current[0].className.replace("active-nav", "");
          this.className += "active-nav";

          // menuLink[i].classList.add('active-nav')
        });
      }
    }
  };
  useEffect(() => {
    window.addEventListener("load", widthChange);

    return () => {
      //cleanup function//
      window.removeEventListener("load", widthChange);
    };
  }, []);

  return (
    <BrowserRouter>
      <MainRoute />

      <CookieConsent
        location="bottom"
        buttonText="Accept All"
        cookieName="myAwesomeCookieName2"
        expires={150}
        containerClasses="cookie-consent-container"
        buttonClasses="cookie-accept-button"
        declineButtonClasses="cookie-decline-btn"
        overlay
        overlayClasses="cookie-overlay"
      >
        We use cookies on our website to give you the most relevant experience
        by remembering your preferences and repeat visits. By clicking “Accept
        All”, you consent to the use of ALL the cookies. However, you may visit
        "Cookie Settings" to provide a controlled consent.
      </CookieConsent>
    </BrowserRouter>
  );
}

export default App;
