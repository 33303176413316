import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';

import Header from '../Header';
import Footer from '../Footer';
import Linklist from '../Linklist';
import OrderProcess from '../OrderProcess/OrderProcess';
import { useSelector } from 'react-redux';

const Home = lazy(() => import('../Home'));
const SignIn = lazy(() => import('../SignIn/SingIn'));
const SignUp = lazy(() => import('../signUp/SignUp'));
const Product_List = lazy(() => import('../Shop-grid/Product_List'));
const Productdetails = lazy(() => import('../Product_Details/Productdetails'));
const Overview = lazy(() => import('../User Dashboard/Overview'));
const Myorder = lazy(() => import('../User Dashboard/Myorder'));
const MyWallet = lazy(() => import('../User Dashboard/Mywallet'));
const Mywishlist = lazy(() => import('../User Dashboard/Mywishlist'));
const Myaddress = lazy(() => import('../User Dashboard/Myaddress'));
const Forgotpass = lazy(() => import('../Forgotpass'));
const Checkout = lazy(() => import('../Checkout'));
const Aboutus = lazy(() => import('../About.us'));
const Contactus = lazy(() => import('../Contact.us'));
const Orderplaced = lazy(() => import('../Order.placed'));
const Faq = lazy(() => import('../Faq/Faq'));
const Bill = lazy(() => import('../Bill'));

const PrivacyPolicy = lazy(() => import('../cms/Privacy.policy'));
const CookiePolicy = lazy(() => import('../Cookie.policy'));
const Terms = lazy(() => import('../cms/Terms'));
const Refund = lazy(() => import('../RefundAndCancilation'));
const Offline = lazy(() => import('../Offline'));
const Menu = lazy(() => import('../Menu'));
const CategoryView = lazy(() => import('../CategoryView'));

const Disclaimer = lazy(() => import('../Disclamer'));
// const Refund = lazy(() => import('../RefundAndCancilation'))
const UserReturn = lazy(() => import('../User Dashboard/Myreturn'));
const SearchPage = lazy(() => import('../search'));
const Intellectual = lazy(() => import('../cms/intellectual'));
const RefundP = lazy(() => import('../cms/refund'));
const returnAndCancelPolicy = lazy(() => import('../cms/return-and-cancel-policy'));
const Payment = lazy(() => import('../cms/payment'));
const Shipping = lazy(() => import('../cms/shipping'));
const SharePage = lazy(() => import('../User Dashboard/sharepage'));
const Wallet = lazy(() => import('../User Dashboard/wallet/wallet'))
const userKyc = lazy(() => import('../User Dashboard/wallet/userkyc'))
const transaction = lazy(() => import('../User Dashboard/wallet/mytransaction'))

const MainRoute = () => {
  const [isAuthenticated, setisAuthenticated] = useState(true)
  const location = useLocation();
  // useEffect(() => {
  //   if (localStorage.getItem('loginpass') === 'true') {
  //     console.log(localStorage.getItem('loginpass'))
  //     setisAuthenticated(true)
  //   }
  // }, [localStorage])

  const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (

    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('loginpass') === 'true' ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        {location.pathname !== '/offline' &&
          location.pathname !== '/singin' &&
          // location.pathname!=='/singin'&&
          location.pathname !== '/bill' ? (
          <Header />
        ) : null}

        <Switch>
          <Route exact path='/' component={Home} />
          {/* <PrivateRoute path="/singin" component={SignIn} isAuthenticated={isAuthenticated} /> */}

          <Route exact path='/singin' component={SignIn} />
          {/* <PrivateRoute path="/singup" component={SignUp} isAuthenticated={isAuthenticated} /> */}

          <Route exact path='/singup' component={SignUp} />
          <Route exact path='/product-details' component={Productdetails} />
          <Route exact path='/product-list' component={Product_List} />
          <Route exact path='/product-list-new' component={Product_List} />
          <Route exact path='/product-list-best' component={Product_List} />

         
          <PrivateRoute path="/myorder" component={Myorder} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/mywallet" component={MyWallet} isAuthenticated={isAuthenticated} />

          {/* <Route exact path='/mywallet' component={MyWallet} /> */}
          <PrivateRoute path="/mywishlist" component={Mywishlist} isAuthenticated={isAuthenticated} />

          {/* <Route exact path='/mywishlist' component={Mywishlist} /> */}
          <PrivateRoute path="/myaddress" component={Myaddress} isAuthenticated={isAuthenticated} />

          {/* <Route exact path='/myaddress' component={Myaddress} /> */}
          <PrivateRoute path="/overview" component={Overview} isAuthenticated={isAuthenticated} />

          {/* <Route exact path='/overview' component={Overview} /> */}
          <Route exact path='/forgot_password' component={Forgotpass} />
          <PrivateRoute path="/check-out" component={Checkout} isAuthenticated={isAuthenticated} />

          {/* <Route exact path='/check-out' component={Checkout} /> */}
          <Route exact path='/About Us' component={Aboutus} />
          <Route exact path='/contactus' component={Contactus} />
          <Route exact path='/Order-placed' component={Orderplaced} />
          <Route exact path='/Faq' component={Faq} />
          <PrivateRoute path="/bill" component={Bill} isAuthenticated={isAuthenticated} />

          {/* <Route exact path='/bill' component={Bill} /> */}
          <Route exact path='/order-process' component={OrderProcess} />
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          <Route exact path='/Cookie-Policy' component={CookiePolicy} />
          <Route exact path='/terms-and-condition' component={Terms} />
          <Route exact path='/AboutUs' component={Aboutus} />
          <Route exact path='/return-Policy' component={Refund} />
          <Route exact path='/offline' component={Offline} />
          <Route exact path='/menu' component={Menu} />
          <Route exact path='/categoryview' component={CategoryView} />
          <Route exact path='/disclamer' component={Disclaimer} />
          <Route exact path='/refund-cancilation' component={Refund} />
          <PrivateRoute path="/return" component={UserReturn} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/my-wallet" component={Wallet} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/my-kYC" component={userKyc} isAuthenticated={isAuthenticated} />
          <PrivateRoute path="/my-transaction" component={transaction} isAuthenticated={isAuthenticated} />

          {/* <Route exact path='/return' component={UserReturn} /> */}
          <Route exact path='/intellectual' component={Intellectual} />
          <Route exact path='/refund' component={RefundP} />
          <Route exact path='/return-and-cancel-policy' component={returnAndCancelPolicy} />
          <Route exact path='/shipping' component={Shipping} />
          <Route exact path='/payment' component={Payment} />
          <Route exact path='/search' component={SearchPage}></Route>
          <Route exact path='/terms&condition' component={Terms} />
          <Route exact path='/referal' component={SharePage} />
          <Redirect to='/' />
        </Switch>
        {location.pathname !== '/offline' &&
          location.pathname !== '/search' &&
          location.pathname !== '/singin' &&
          location.pathname !== '/bill' ? (
          <Footer />
        ) : null}
      </Suspense>
    </>
  );
};
export default MainRoute;
