import React, { useEffect } from "react";
import './OrderProcess.css';
import { useCookies } from 'react-cookie';

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export default function OrderProcess() {

    const { state } = useLocation();
    const history = useHistory();

    useEffect(() => {
        let Auth = sessionStorage.getItem('orderAuth');
        //     if(Auth==='true'){
        //         history.push({pathname:'/'})

        //     } 
        //    else{
        setTimeout(() => {
            history.push({pathname:'/Order-placed', 
                addId: state.addressState,
                totalAmount: state.Obj.orderTotal
            })
        }, 2000)
        //    }

    }, [])
    

    return (
        <>
            <div className="wrapper">
                <div className="order-container">
                    <div className="loader">
                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                    </div>

                    <div className="write">
                        <h4>Order is Placed!!!</h4>
                    </div>
                </div>

            </div>
        </>
    )
}