import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useCookies } from 'react-cookie';
import emptyCart from './emptyImg/Empty--Cart.jpg';
import instance from './Url';
import { ToastContainer, toast } from 'react-toastify';
import { getCartlist } from './Redux/cart.action';

const cartselector = (state) => {
  return state.cart;
};

const quantitySelector = (state) => {
  return state.quantity;
};
const globalSelector = (state) => {
  return state.global;
};

const cartCanvas = (state) => {
  return state.CartCav;
};

const cartQuentitySelector = (state) => {
  return state.cartquen;
};

const hub_details = (state) => {
  return state.pin_hub;
};
export default function Cartmodal(props) {
  const hub_data = useSelector(hub_details);
  const cartlist = useSelector(cartselector);

  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['token', '_cid', '_wid', 'user']);
  const [isUpdate, setIsupdate] = useState(false);
  // const [delivery, setdelivery] = useState(0);
  const [taxData, setTaxData] = useState({
    taxPercentage: 0,
    taxAppOn: 0,
    shippingCharge: 0,
    shippingAppOn: 0,
    minOrderVal: 0,
  });
  // console.log(cartlist);
  // useEffect(() => {
  //   setdelivery(deliveryCharges)
  // }, [deliveryCharges])
  const [cartvalue, setCartValue] = useState({
    totalAmount: 0,
    taxAmount: 0,
  });

  useEffect(() => {
    valueChange();
  }, [props.cartList]);

  console.log(props);

  // function getCartInformation() {
  //   // const dispatch = useDispatch();
  //   instance
  //     .post(
  //       'customercartfavs/list/web/',
  //       { addressId: '' },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${cookies.token}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       setdeliveryCharges(res.data.deliveryCharges);
  //     });
  // }

  const valueChange = () => {
    let totalCalculate = {
      totalAmount: 0,
      taxAmount: 0,
      totalproductAmount: 0,
      totalQuentity: 0,
    };

    for (let cart of cartlist) {
      totalCalculate.totalAmount += cart.totalAmt;
      totalCalculate.taxAmount += cart.taxAmt;
    }

    for (let cart of cartlist) {
      totalCalculate.totalproductAmount += cart.totalAmt + cart.taxAmt;
    }

    for (let cart of cartlist) {
      totalCalculate.totalQuentity += cart.quantity;
    }

    setCartValue(totalCalculate);
  };
  const deleteItemFromCart = (item) => {
    // console.log(item)
    dispatch({ type: 'cartitem-deleted', payload: item });
    toast.success(` ${item.productName} deleted from cart`, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const cartQuantityIncrease = (data) => {
    // console.log(data);
    if (data.quantity > data.minQty - 1) {
      toast.error(`Sorry product Quantity cannot increased more than ${data.minQty}`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (data.quantity > data.qtyLeftInStock - 1) {
      toast.error(`Sorry Product quantity in stock`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let obj = { ...data, quantity: data.quantity + 1 };
    dispatch({ type: 'cartitem-quantity-increase', payload: obj });
  };

  async function increaseCartItem(item) {
    let token = localStorage.getItem('token');
    console.log(token);
    if (localStorage.getItem('loginpass') === 'true') {
      if (item.quantity < item.stock) {
        try {
          let data = {};
          instance
            .put(`customercartfavs/quantityIncrease/web/${item._id}`, data, {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            })
            .then((res) => {
              if (res.status === 200) {
                getCartlist(token, hub_data.hub_id.id).then((res) => {
                  dispatch({ type: 'cartdata-by-user', payload: res.data.data });
                });
              }
            });
        } catch (error) {
          toast.error(`Something Wrong.`, {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.error(`Sorry This Product is Stock Only ${item.stock} Items`, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      history.push('/singin');
    }
  }

  async function decreaseCartitem(item) {
    let token = localStorage.getItem('token');
    if (localStorage.getItem('loginpass') === 'true') {
      let data = {};
      instance
        .put(`customercartfavs/quantityDecrease/web/${item._id}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          if (res.status === 200) {
            getCartlist(token, hub_data.hub_id.id).then((res) => {
              dispatch({ type: 'cartdata-by-user', payload: res.data.data });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      history.push('/singin');
    }
  }

  const goToCheckOut = () => {
    if (cookies.token !== null) {
      checkOutOfStockOnCartitem().then((res) => {
        if (res.length === 0) {
          let obj = {
            cartValues: { ...cartvalue },
            senderName: 'cart',
            deliveryCharge: props.deliveryCharges,
          };
          // console.log(obj)
          history.push('/check-out', obj);
          props.hideCartlist();
        } else {
          toast.success('Item Out Of stock Please Remove to Continue', {
            position: 'top-center',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } else {
      toast.error(`Please Login To Continue.`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  function deleteCartItem(data) {
    if (localStorage.getItem('loginpass') === 'true') {
      let token = localStorage.getItem('token');

      instance
        .delete(`customercartfavs/web/${data._id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch({ type: 'remove_cart_item', payload: data });
          getCartlist(token, hub_data.hub_id.id).then((res) => {
            dispatch({ type: 'cartdata-by-user', payload: res.data.data });
          });
        });
    } else {
      toast.error(`Please Login First.`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  async function checkOutOfStockOnCartitem() {
    let _cartList = [...props.cartList];
    let arr = [];
    let stockEmpty = _cartList.filter((item) => {
      return item.stock === 0;
    });
    return stockEmpty;
  }

  return (
    <>
      {props.cartList.length === 0 ? (
        <div className='cart-item'>
          <img src={emptyCart} alt='empty-cart-img' width={300} />
        </div>
      ) : (
        <div className='bg-cart'>
          {props.cartList.map((product) => (
            <>
              <div className='cart-item-box' key={product._id}>
                <div className='cart-item-pro'>
                  {/* { product.discountFlag===1?
                        <div className="cart-product-img">
                            <img src={product.image} alt="cart product" />
                            <div className="offer-badge">{product.discount}% OFF</div>
                        </div>
                        : */}

                  <div className='cart-product-img'>
                    {/* <button type="button" className="cart-close-btn" onClick={() => deleteCartItem(product)} ><i className="uil uil-trash-alt"></i></button> */}

                    <img
                      src={product.productImage}
                      alt='cart product'
                      onClick={() => history.push('/product-details', { id: product.productId })}
                    />
                    {/* <p> {product.stock !== 0 ? 'Available' : 'Not-Available'}</p> */}
                    {/* <p className='ml-4'>{product.stock !== 0 ? <p className='text-stock'>(In Stock)</p> : <p className='text-danger'>(Out of Stock)</p>}</p> */}
                  </div>
                  {/* } */}
                  <div className='cart-text'>
                    <h4 className='product-line-cart'>{product.productName}</h4>

                    <div className='cart-radio'>
                      {/* <div className="cart-radio"> */}
                      {/* {product.discountFlag === 0 ?
                                            <ul className="kggrm-now">
                                                <li>

                                                    <label >{product.sku} / ₹ {product.subTotal}</label>
                                                </li>
                                            </ul>
                                            : */}
                      {/* <ul className="kggrm-now">
                                            <li>

                                                <label >{product.packetSize} / ₹ {product.orderPrice}</label>
                                            </li>
                                        </ul>
                                    </div> */}
                    </div>
                    <div className='qty-group'>
                      <div className='cart-price'>
                        <p>{product.packetSize}</p>
                        <i> {`₹ ${product.totalAmt.toFixed(2)}`}</i>
                      </div>
                      <div className='quantity buttons_added'>
                        <input
                          type='button'
                          value='-'
                          className='minus minus-btn cart-inc-btn'
                          onChange={() => {}}
                          onClick={() => decreaseCartitem(product)}
                        />
                        <input
                          type='number'
                          step='1'
                          name='quantity cart-inc-btn'
                          value={product.quantity}
                          className='input-text qty text cart-inc-btn'
                          onChange={() => {}}
                        />
                        <input
                          type='button'
                          value='+'
                          className='plus plus-btn cart-inc-btn'
                          onChange={() => {}}
                          onClick={() => increaseCartItem(product)}
                        />
                        {/* <input type="button" className="input-text qty text" value={`₹ ${product.totalAmt}`} > */}
                      </div>
                      <button type='button' className='cart-close-btn' onClick={() => deleteCartItem(product)}>
                        <i className='uil uil-trash-alt'></i>
                      </button>

                      {/* <div> */}
                      {/* <div className="qty-group"> */}
                      {/* <div className="quantity buttons_added">
                                                    <p>₹ {product.totalAmt}</p>
                                                </div> */}
                      {/* </div> */}

                      {/* </div> */}

                      {/* {product.discountFlag===1?
                                <div className="cart-item-price">₹{(product.discountedPrice*product.quantity).toFixed(2)} <span>₹{(product.price*product.quantity).toFixed(2)}</span></div>
                            :
                            // <div className="cart-item-price">₹{(product.discountedPrice*product.quantity).toFixed(2)} </div>
                            
                            } */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
          <div className='cart-item'>
            <div className='bill-details'>
              <h3>
                <strong>Bill Details </strong>
              </h3>
              <div className='main-total-cart'>
                <h2>SubTotal (Including GST)</h2>
                <span>₹{cartvalue.totalAmount.toFixed(2)}</span>
              </div>
              {/* <div className='main-total-cart'>
                <h2>Delivery Charges</h2>
                <span>
                  {props.deliveryCharges === 0 ? <p className='delivery-charges'>Free</p> : <p>₹{props.deliveryCharges}</p>}
                </span>
              </div> */}
              <div className='main-total-cart gad-tl'>
                <h2>Grand Total</h2>
                <span>₹{cartvalue.totalAmount.toFixed(2)}</span>
              </div>
              <div className='cart-total-dil saving-total '>
                {/* <h4>SubTotal (Including GST) </h4>
                <span>₹{cartvalue.totalAmount.toFixed(2)}</span> */}
              </div>
            </div>
          </div>
          <div className='cart-items gad-tl'>
            <h2>Cancellation Policy</h2>
            <p>
              Orders cannot be cancelled once packed for delivery. In case of unexpected delays, a refund will be
              provided, if applicable.
            </p>
          </div>
          <div className='cart-items '>
            <button
              className='cart-checkout-btn hover-btn ck-btn'
              onClick={goToCheckOut}
              style={{ border: 'none', backgroundColor: '#fba825' }}
            >
              Proceed to Checkout
            </button>
          </div>
          <div>
            {/* <div className="cart-total-dil saving-total ">
                    <h4>Total Saving</h4>
                    <span>₹{cartvalue.totalDiscount.toFixed(2)}</span>
                </div> */}
            {/* <div className="cart-total-dil saving-total ">
                            <h4>Tax</h4>
                            <span>₹{cartvalue.taxAmount.toFixed(2)}</span>
                        </div> */}

            {/* <div className='main-total-cart'>
              <h2>Total Amount </h2>
              <span>₹{(cartvalue.totalAmount + deliveryCharges).toFixed(2)}</span>
            </div> */}
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
}
