import React, { useState, useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import img from './emptyImg/wishlist.png';
import instance from './Url';
import { deletewishList, getWishlist } from './Redux/wishList.action';
import { getCartlist } from './Redux/cart.action';

const wishlistSelector = (state) => {
  return state.wish;
};

const hub_details = (state) => {
  return state.pin_hub;
};
const quantitySelector = (state) => {
  return state.quantity;
};
function Wishlist(props) {
  const [cookies, setCookie] = useCookies(['token']);
  const history = useHistory();
  const [token, setToken] = useState('');
  const dispatch = useDispatch();

  const hub_data = useSelector(hub_details);
  const wishLists = useSelector(wishlistSelector);
  const quantityList = useSelector(quantitySelector);
  const [wishList, setwishlist] = useState(wishLists);

  // useEffect(() => {

  //     getWishlist(cookies.token, hub_data.hub_id.id).then((res) => {
  //         dispatch({ type: 'get-wishlist', payload: res.data.data })
  //     })
  // }, [wishList])

  useEffect(() => {
    comapreQuantityList();
  }, [quantityList]);

  const comapreQuantityList = () => {
    let array = [...wishList];
    for (let p of array) {
      for (let c of quantityList) {
        if (c._id === p._id) {
          p.quantity = c.quantity;
        }
      }
    }
    // setwishlist(array)
  };

  const addToCart = (data) => {
    if (localStorage.getItem('loginpass') === 'true') {
      let token = localStorage.getItem('token');
      if (data.stock !== 0) {
        let obj = { ...data };
        instance
          .post(
            'customercartfavs/web/',
            {
              productId: data.productId,
              hubId: hub_data.hub_id.id,
              itemType: 'C',
              quantity: data.quantity,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.status === 201) {
              props.hideWishlist();
              let obj = { ...data, isCart: true };
              dispatch({ type: 'added-to-cart', payload: obj });
              getCartlist(token, hub_data.hub_id.id).then((res) => {
                dispatch({ type: 'cartdata-by-user', payload: res.data.data });
              });
              //    dispatch({ type: 'cartitem-added', payload: obj })
              toast.success('Added', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.success('Somthing Went Wrong!', {
                position: 'top-center',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((err) => {
            toast.success(err.response.data.message, {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
      } else {
        toast.success('Not enough product is in stock currently', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      // history.push('/signin')
      toast.success('Please Login Frist.', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const addQuantity = (data) => {
    if (data.stock > data.quantity) {
      if (data.quantity >= 5) {
        toast.error(`Sorry product Quantity cannot increased more than 5`, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      let obj = { ...data, quantity: data.quantity + 1 };
      dispatch({ type: 'quantity-added', payload: obj });
    } else if (data.stock === 0) {
      toast.error(`Sorry Currently Out Of Stock.`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(`Sorry Currently Stock Only ${data.stock} Items`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const reduceQuantity = (data) => {
    let obj = { ...data, quantity: data.quantity === 1 ? 1 : data.quantity - 1 };
    dispatch({ type: 'quantity-reduced', payload: obj });
  };
  function deleteWishLists(item) {
    if (localStorage.getItem('loginpass') === 'true') {
      let token = localStorage.getItem('token');
      deletewishList(token, item._id).then((res) => {
        if (res.status === 200) {
          // console.log(res, 'get respone from api...')
          dispatch({ type: 'remove_wishitem', payload: item });
          toast.success(res.data.message, {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          getWishlist(token, hub_data.hub_id.id).then((res) => {
            setwishlist(res.data.data);
            dispatch({ type: 'get-wishlist', payload: res.data.data });
          });
        } else {
          toast.error('Something Wrong', {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } else {
      toast.error('Please login First', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const deleteItems = (data) => {
    let obj = { ...data, isFav: 0 };
    dispatch({
      type: 'remove_wishList',
      payload: obj,
    });
    toast.success(`${data.productName} Product  deleted from wishlist`, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const gotoProductDetails = (id1) => {
    history.push({ pathname: '/product-details', state: { id: id1 } });
    props.hideWishlist();
  };

  return (
    <>
      <div className='col-lg-12 col-md-12 pl-0'>
        <div className='pdpt-bg'>
          {wishList.length === 0 ? (
            <div className='wishlist-body-dtt'>
              <img src={img} alt='empty-wishlist' width={300} />
            </div>
          ) : (
            <div className='wishlist-body-dtt'>
              {wishList.map((item) => (
                <div className='cart-item' key={item._id}>
                  {/* {item.discountFlag===1?
                                        <div className="cart-product-img" onClick={()=>{gotoProductDetails(item._id)}}>
                                         <img src={item.productImage[0].src} alt="cart pic"
                                         
                                        />
                                            <div className="offer-badge">{item.discount}% Off</div>
                                        </div>
                                        : */}
                  <div className='cart-product-img' onClick={() => gotoProductDetails(item.productId)}>
                    <img src={item.productImage} alt='cart pic' />
                  </div>

                  {/* } */}
                  <div className='cart-text'>
                    <h4>{item.productName}</h4>
                    <div className='cart-radio'>
                      {/* <ul className="kggrm-now">
                                                <li>

                                                    <label >{item.packetSize} / ₹ {item.salePrice}</label>
                                                </li>
                                            </ul> */}
                      <div className='qty-group'>
                        <div className='cart-price'>
                          <p>{item.packetSize}</p>
                          <i> ₹ {item.salePrice}</i>
                        </div>
                      </div>
                    </div>
                    {/* {item.discountFlag===1?
                                        <div className="cart-item-price"> ₹{item.discountedPrice} <span> ₹{item.price}</span></div>
                                         :
                                        <div className="cart-item-price"> ₹{item.price} </div>
                                         } */}
                    <button type='button' className='cart-close-btn-wishlist' onClick={() => deleteWishLists(item)}>
                      <i className='uil uil-trash-alt'></i>
                    </button>

                    {/* <div className="qty-cart">

                                            <div className="quantity buttons_added">
                                                <input type="button" value="-" className="minus minus-btn" style={{ backgroundColor: '#c7c7c7' }} onClick={() => reduceQuantity(item)} onChange={() => { }} />
                                                <input type="number" step="1" name="quantity" value={item.quantity}
                                                    className="input-text qty text" onChange={() => { }} />
                                                <input type="button" value="+" className="plus plus-btn" style={{ backgroundColor: '#c7c7c7' }} onClick={() => addQuantity(item)} onChange={() => { }} />

                                            </div>

                                            <span data-tooltip={'Add To Cart'} onClick={() => addToCart(item)} className="cart-icon "><i className='uil uil-shopping-cart-alt' ></i></span>

                                        </div> */}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default Wishlist;
