import React, { useState, useEffect, useRef, useCallback } from 'react';
import instance from './Url';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import locationImage from '../images/location.svg';

import logo from '../images/logo.png';

import slogo from '../images/so-logo.png';

import SelectModal from './Selectmodal';
import { Navbar, Nav, Container, Dropdown, Form, FormControl, Modal, Spinner } from 'react-bootstrap';

import { useSelector, useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Select from 'react-select';

import Cartmodal from './Cartmodal';
import { NavLink } from 'react-router-dom';
import Wishlist from './wishlist';
import { getCartlist } from './Redux/cart.action';
import { getWishlist } from './Redux/wishList.action';
import userIcon from './assets/login-user-icon.png';
import searchIcon from './assets/search-icon.png';
import cartImg from '../components/offers/best-offers/shopping-cart1.png';
import pinVector from './assets/pin-modal-vector.png';
import menubar from '../images/menu-bar.png';
// import deliverySlot from './Redux/deliveryslot';

const selectCartList = (state) => {
  return state.cart;
};

const wishlistSelector = (state) => {
  return state.wish;
};
const cartCanvas = (state) => {
  return state.CartCav;
};

const userData = (state) => {
  return state.userProfile.phone;
};

const hub_details = (state) => {
  return state.pin_hub;
};

const cart_items_list = (state) => {
  return state.Cartitemlist;
};
const deliverySlot = (state) => {
  return state.deliverySlot;
};
const Header = () => {
  // console.log(deliverySlot,'slotttt')
  const [modalShow1, setModalShow1] = useState(false);

  const [show1, setShow1] = useState(false);
  const [loc, setLoc] = useState([]);

  const [num, setNum] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'user', '_cid', '_wid']);
  const [token, setToken] = useState('');
  const [user, setUser] = useState({});
  const [cShow, setcShow] = useState(false);
  const [cShow1, setcShow1] = useState(false);
  const [cShow2, setcShow2] = useState(false);
  const [sValue, setsValue] = useState('');
  const dispatch = useDispatch();
  const [navToggole, setNavToggle] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [showNotification, setShownotification] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
    history.push('/');
  };
  const [deleveryChages, setDeliveryChages] = useState(0);

  const cartList = useSelector(selectCartList);
  const history = useHistory();
  const [list, setList] = useState([]);
  const [pincode, setPincode] = useState([]);
  const wishList = useSelector(wishlistSelector);
  const CartCanvas = useSelector(cartCanvas);
  const { phone } = useSelector(userData);
  const Cart_items_lists = useSelector(cart_items_list);
  const delivery_Slot = useSelector(deliverySlot);
  const [catagoryList, setCatagoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const pin = useRef(null);

  const [searchList, setSearchList] = useState([]);
  const [isHubid, setisHubid] = useState(false);

  const [selectwarehouse, setselectwarehouse] = useState(false);
  const [searchbodyShow, setSearchbodyshow] = useState(false);
  const [isSearchPincode, setIssearchpincode] = useState('');

  const hub_data = useSelector(hub_details);

  const [selectpincode, setSelectpincode] = useState({
    pin: '',
    hub_id: {
      id: '',
      name: '',
      address: '',
    },
  });

  const [hubList, setHublist] = useState({});
  const [issetpincode, setIssetpincode] = useState(false);
  // const [cartlist, setcartlist] = useState([])
  useEffect(() => {
    console.log('header mounted....');
    if (localStorage.getItem('loginpass') === 'false') {
      dispatch({ type: 'clearCart' });
      dispatch({ type: 'clear-wishlist' });
    }
    // getPincodeList();
    if (navigator.onLine) {
      let tok = cookies.token;
      // console.log(tok);
      setToken(tok);
      let user1 = cookies.user;
      // console.log(user1);
      setUser(user1);
      if (localStorage.getItem('loginpass') === 'true') {
        getUserProfile();
        // getCartList();
      }

      // getcatList();
      // console.log(CartCanvas);
      setsValue('');
    } else {
      history.push('/offline');
    }
    // console.log(delivery_Slot.displayContent)
  }, []);

  function getcartlists() {
    let token = localStorage.getItem('token');
    getCartlist(token, hub_data.hub_id.id).then((res) => {
      dispatch({ type: 'cartdata-by-user', payload: res.data.data });
      // setcartlist(res.data.data)
      // console.log(res.data.data)
    });
  }

  function wishlists() {
    let token = localStorage.getItem('token');
    getWishlist(token, hub_data.hub_id.id).then((res) => {
      dispatch({ type: 'get-wishlist', payload: res.data.data });
    });
  }

  // useEffect(() => {
  //     wishlists()
  //     getcartlists();
  // }, [])
  function getwarehouseBypincode(pin) {
    instance.get(`hubs/getHub/bypincode/${pin}`).then(() => {});
  }

  const getCartList = () => {
    instance
      .get(`cart/get-cart-list/${cookies._cid}`, {
        headers: {
          Authorization: cookies.token,
        },
      })
      .then((res) => {
        // console.log(res.data[0].products);
        dispatch({ type: 'load-cartdata', payload: res.data[0].products });
      });

    instance
      .get(`fav/get-fav-list/${cookies.user._id}`, {
        headers: {
          Authorization: cookies.token,
        },
      })
      .then((res) => {
        // console.log(res.data[0].products);
        dispatch({ type: 'load-wishdata', payload: res.data[0].products });
      });
  };

  //Logout function
  const logOut = () => {
    removeCookie('user');
    removeCookie('_cid');
    removeCookie('_wid');
    setCookie('token', null);
    // sessionStorage.removeItem('loginpass');
    localStorage.setItem('loginpass', 'false');
    localStorage.removeItem('token');
    dispatch({ type: 'clearCart' });
    dispatch({ type: 'clear-wishlist' });
    // localStorage.removeItem("cart1")
    // dispatch({ type: 'clearCart' });
    history.push('/');
    window.location.reload();
  };

  const handleClose = () => {
    setSearchbodyshow(false);
    setcShow(false);
    setselectwarehouse(false);
  };

  const handleShowMessage = () => {
    setShownotification(true);
  };

  const handleCloseMessage = () => {
    setShownotification(false);
  };

  const showNav = () => {
    setNavToggle(true);
  };

  const hideNav = () => {
    setNavToggle(false);
  };

  const handleShow = () => setcShow(true);
  const showWishlist = () => setcShow1(true);
  const hideWishlist = () => setcShow1(false);
  const showCartlist = () => {
    dispatch({ type: 'open-canvas' });
  };
  const hideCartlist = () => {
    dispatch({ type: 'close-canvas' });
  };
  const getcatList = () => {
    setLoading(true);
    instance.get('categories/web/list').then((req) => {
      setCatagoryList(req.data.data);
      setLoading(false);
    });
  };

  async function getSubCatbyId(data) {
    let res = await instance.get(`/category/get-subCategory-list/${data._id}`);
    // console.log(res.data.data);
    let array = [];
    for (let val of res.data.data) {
      let id = val._id;
      array.push({ categoryId: id });
    }
    history.push({
      pathname: '/product-list',
      state: { productType: 'category', name: data.categoryName, pData: array },
      search: `?id=${data._id}&name=${data.categoryName}`,
    });
    handleClose1();
  }

  const bestSellproductToPrList = () => {
    hideNav();
    history.push({
      pathname: '/product-list-best',
      state: {
        productType: 'best',
        name: 'Best Selling Products',
      },
      search: '&name=Best Selling Products',
    });
  };

  const newproductToprList = () => {
    hideNav();
    history.push({
      pathname: '/product-list-new',
      state: {
        productType: 'new',
        name: 'New-Added-Products',
      },
      search: '&name=New Added Products',
    });
  };

  const offersToProduct = () => {
    history.push({
      pathname: '/product-list',
      state: {
        productType: 'offers',
        name: 'Offers-Product for you',
      },
      search: `&name=offers`,
    });
  };

  const handlepin = (e) => {
    e.preventDefault();
    // console.log(e.target.value);
    dispatch({ type: 'load-pin', payload: e.target.value });
  };
  //get user profile information
  function getUserProfile() {
    let token = localStorage.getItem('token');
    instance
      .get('customers/web/profile', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUserProfile(res.data.data);
      });
  }

  useEffect(() => {
    if (hub_data.pincode !== '') {
      setSelectpincode({ ...selectpincode, pin: hub_data.pincode });
    }

    if (sessionStorage.getItem('setPincode') === null) {
      setIssetpincode(true);
      // sessionStorage.setItem('setPincode', false)
      // setIssetpincode(true)
    } else {
      setIssetpincode(false);
    }
    getPincodeLists();
  }, []);

  useEffect(() => {}, [sessionStorage]);

  function getPincodeLists() {
    instance.get('pincodes/web/list').then((res) => {
      // let _pin_array = [];

      let _res_pin = res.data.data
        .filter((data) => {
          return data.isActive === '1';
        })
        .map((res) => {
          return {
            value: `${res.pincode}`,
            label: `${res.pincode},West Bengal`,
          };
        });
      setPincode(_res_pin);
      // for (let p of res.data.data) {

      //     let obj = {
      //         value: `${p.pincode}`,
      //         label: `${p.pincode}`
      //     }
      //     _pin_array.push(obj)
      // }
      // setPincode(_pin_array)

      // console.log(_pin_array)
      // setPincode(pinArray)
    });
  }

  useEffect(() => {
    if (localStorage.getItem('loginpass') === 'true') {
      let token = localStorage.getItem('token');
      getWishlist(token, hub_data.hub_id.id).then((res) => {
        console.log(res);
        if (typeof res === 'undefined') {
          return;
        } else {
          dispatch({ type: 'get-wishlist', payload: res.data.data });
        }
        // console.log(res.data.data, 'wishlist data after logon succesfully!!')
      });
      getCartlist(token, hub_data.hub_id.id).then((res) => {
        setDeliveryChages(res?.data?.deliveryCharges);
        if (typeof res === 'undefined') {
          return;
        } else {
          dispatch({ type: 'cartdata-by-user', payload: res.data.data });
        }
      });
    }

    if (hub_data.hub_id.id === '') {
      setIssetpincode(true);
      // setisHubid(true)
    } else if (hub_data.hub_id.id !== '') {
      setIssetpincode(false);
      // setisHubid(false)
    }
  }, [hub_data, Cart_items_lists]);

  useEffect(() => {
    let { pin } = selectpincode;
    if (pin !== '') {
      instance.get(`hubs/getHub/bypincode/${pin.value}`).then((res) => {
        //   console.log(res.data.data.length)
        if (res.data.data.length !== 0) {
          res.data.data.map((hub) => {
            // let hub_obj = { id: '', name: '', address: '' }
            let payload_obj = {
              pincode: pin,
              hub_id: {
                id: hub_data.id,
                name: hub_data.name,
                address: hub_data.address,
              },
            };

            payload_obj.hub_id.id = hub._id;
            payload_obj.hub_id.name = hub.name;
            payload_obj.hub_id.address = hub.address;

            // localStorage.setItem('hub_data'  , JSON.stringify(payload_obj))
            dispatch({ type: 'set_user_pin_hub', payload: payload_obj });
            setisHubid(true);
            // setIssetpincode(false)
          });
        } else {
          let empty_payload = {
            pincode: pin,
            hub_id: {
              id: '',
              name: '',
              address: '',
            },
          };

          dispatch({ type: 'empty_hub', payload: empty_payload });
          setisHubid(true);
          // setIssetpincode(false)
        }
      });
    }
  }, [selectpincode]);
  useEffect(() => {
    // getSeachData(sValue)
    let value = {
      data: sValue,
    };
    dispatch({ type: 'searchKeyword', payload: value });
  }, [sValue]);
  // calling search api after getting search value

  function closeSearchModel(items) {
    history.push('/product-details', { id: items._id });
    setShow1(false);
    setSearchbodyshow(false);
  }

  const getlocationDetails = () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const { latitude, longitude } = await position.coords;
      let url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}}&longitude=${longitude}&localityLanguage=en`;

      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data)
        });
    });
  };

  const position = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) => {
        // console.log(position)
      },
      (err) => console.log(err)
    );
    // console.log(this.state.latitude)
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#fba825' : 'white',
      borderColor: state.isSelected ? '#fba825' : 'white',
    }),
  };

  return (
    <>
      <header className='header clearfix mobile-header'>
        <div className='anouncement'>
          {/* <marquee width='90%' direction='left'> */}
          {delivery_Slot.displayContent}
          {/* <p>
              Daily Mandi customer care number +91 8777579395 is available from 10am to 8pm for queries feedback or any
              issues | Normal Delivery starts from 11 am
            </p> */}
          {/* </marquee> */}
        </div>
        <div className='container'>
          <div className='top-header-group'>
            <div className='top-header'>
              <div className='res_main_logo'>
                <NavLink to='/'>
                  <img src={logo} alt='logo' />
                </NavLink>
              </div>
              <div className='main_logo' id='logo'>
                <NavLink to='/'>
                  <img src={logo} alt='logo' />
                </NavLink>
                <NavLink to='/'>
                  <img className='logo-inverse' src={logo} alt='logo' />
                </NavLink>
              </div>
              <div className='select_location'>
                <div className='ui search   left icon input '>
                  <div className='pin_dropdown'>
                    <Select
                      className='w-5 srch103'
                      options={pincode}
                      value={selectpincode.pin}
                      onChange={(e) => setSelectpincode({ ...selectpincode, pin: e })}
                      isSearchable={true}
                    />
                  </div>
                </div>
                {/* <p>{delivery_Slot.displayContent}</p> */}
              </div>

              <Modal
                show={issetpincode}
                onHide={handleClose}
                className=''
                // size="lg"
                aria-labelledby='contained-modal-title-vcenter'
                centered
              >
                <Modal.Body className=''>
                  <div className='pincode-vector d-flex justify-content-center'>
                    <img src={pinVector} alt='Vector Image' className='login-pinvec' />
                  </div>
                  <div className='d-flex justify-content-center mb-2'>
                    <p className='fs-5'> Where do you want us to deliver?</p>
                  </div>
                  <div className='d-flex justify-content-center mb-2'>
                    <p className='fs-7'> Choose the pincode of your delivery</p>
                  </div>

                  <div className='d-flex justify-content-center'>
                    {/* <p>Where do you want us to deliver?</p> */}
                    {/* <div className="  "> */}

                    <div className='pin_dropdown-mdadal'>
                      <Select
                        className='w-5 greens-sel'
                        styles={customStyles}
                        options={pincode}
                        // placeholder="700157"
                        value={selectpincode.pin}
                        onChange={(e) => setSelectpincode({ ...selectpincode, pin: e })}
                        isSearchable={true}
                      />

                      {/* <div >
                                                <span className='location' onClick={() => {getlocationDetails()}} ><img src={locationImage} /> My Current Location.</span>
                                            </div> */}

                      {/* <span>      <i class="bi bi-geo-alt bg-green-300 "></i></span> */}
                    </div>
                    <p>
                      {isHubid === true ? (
                        <div className='pin-val'>
                          Unfortunately, we currently do not deliver in your area. We hope to serve you soon.
                        </div>
                      ) : null}
                    </p>
                  </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
                {/*  */}
              </Modal>

              <div className='header_right'>
                <ul>
                  {/* <li>
                                     <a onClick={handleShowMessage} className="offer-link"><i className="uil uil-bell " ></i></a>
                                     <Offcanvas show={showNotification} onHide={handleCloseMessage} placement={'end'}>
                                            <Offcanvas.Header closeButton>
                                                <Offcanvas.Title>Notification </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            
                                        </Offcanvas>
                                        <a href="!#" className="offer-link"><i className="uil uil-phone-alt"></i>1800-000-000</a>
                                    </li> */}
                  <li>
                    <a onClick={offersToProduct} className='offer-link'>
                      <i className='uil uil-gift'></i>Offers
                    </a>
                  </li>
                  {/* <li>
                                    <NavLink to='/Faq' className="offer-link"><i className="uil uil-question-circle"></i>Help</NavLink>
                                </li> */}
                  <li style={{ border: 'none' }}>
                    <a className='option_links' title='Wishlist' style={{ border: 'none' }} onClick={showWishlist}>
                      <i className='uil uil-heart icon_wishlist'></i>
                      <span className='noti_count1'>{wishList === undefined ? 0 : wishList.length}</span>
                    </a>

                    <Offcanvas show={cShow1} onHide={hideWishlist} placement={'end'}>
                      <Offcanvas.Header className='bs-canvas-header side-cart-header p-3 ' closeButton>
                        <Offcanvas.Title>Wishlist </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <Wishlist wishlist={wishList} hideWishlist={hideWishlist} />
                      </Offcanvas.Body>
                    </Offcanvas>
                  </li>
                  {localStorage.getItem('loginpass') === 'false' || localStorage.getItem('loginpass') === null ? (
                    <li className='ui dropdown'>
                      <div
                        onClick={() => {
                          history.push('/singin');
                        }}
                        className='opts_account'
                      >
                        {/* <i className="fa fa-circle-user"></i> Log In */}
                        <img src={userIcon} alt='User Icon' className='mobile-user' />
                      </div>
                    </li>
                  ) : (
                    <li className='ui dropdown'>
                      <Dropdown>
                        <Dropdown.Toggle variant='Light' id='dropdown-basic'>
                          <div className='opts_account'>
                            {/* <img src={img2} alt="userImg" />
                                                    <span className="user__name">{user.customerName}</span>
                                                    <i className="uil uil-angle-down"></i> */}

                            {typeof userProfile.name !== 'undefined' ? (
                              <span>
                                {' '}
                                <img src={userIcon} alt='User Icon' className='mobile-user' />
                              </span>
                            ) : (
                              <span>
                                {/* <i className='fa fa-circle-user'></i> {userProfile.phone} */}
                                <img src={userIcon} alt='User Icon' className='mobile-user' />{' '}
                              </span>
                            )}
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <div
                              className='item channel_item'
                              onClick={() => {
                                history.push('/overview');
                              }}
                            >
                              <i className=' uil uil-user icon__1 '></i>Profile
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <div
                              className='item channel_item'
                              onClick={() => {
                                history.push('/myorder');
                              }}
                            >
                              <i className='uil uil-box icon__1'></i>Orders
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <div
                              className='item channel_item'
                              onClick={() => {
                                history.push('/mywishlist');
                              }}
                            >
                              <i className='uil uil-heart icon__1'></i>Wishlist
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <div
                              className='item channel_item'
                              onClick={() => {
                                history.push('/myaddress');
                              }}
                            >
                              <i className='uil uil-location-point icon__1'></i>
                              Address
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div
                              className='item channel_item'
                              onClick={() => {
                                history.push('/my-transaction');
                              }}
                            >
                              <i className='uil uil-heart icon__1'></i>Wallet
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div
                              className='item channel_item'
                              onClick={() => {
                                history.push('/my-KYC');
                              }}
                            >
                              <i className='uil  uil-user-square'></i>KYC Information
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div onClick={logOut} className='item channel_item'>
                              <i className='uil uil-lock-alt icon__1'></i>Logout
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className='sub-header-group'>
            {/* <p>{delivery_Slot.displayContent}</p> */}
            <div className='sub-header'>
              {/* <p>{delivery_Slot.displayContent}</p> */}

              <div className='search120'>
                <div className='ui search'>
                  <Form className='ui left icon input swdh10'>
                    <img src={searchIcon} alt='Search Icon' className='ser-icon' />
                    <input
                      type='search'
                      placeholder='Search for products..'
                      className='prompt srch10'
                      aria-label='Search'
                      onChange={(e) => setsValue(e.target.value)}
                      onClick={() => history.push({ pathname: '/search' })}
                    />
                  </Form>
                </div>
              </div>

              <Offcanvas show={cShow} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <SelectModal handleClose={handleClose} />
                </Offcanvas.Body>
              </Offcanvas>

              <Navbar className='py-3 m-view' expand='lg'>
                <Container className='container-fluid'>
                  <div
                    id='basic-navbar-nav'
                    className='d-flex flex-column flex-lg-row flex-xl-row justify-content-lg-end bg-dark1 p-3 p-lg-0 mt1-5 mt-lg-0 mobileMenu'
                  >
                    <Nav>
                      <ul className='navbar-nav main_nav align-self-stretch'>
                        <li className='nav-item'>
                          <NavLink exact to='/' className='nav-link' title='Home' onClick={hideNav}>
                            <i className='fa-solid fa-house'></i> Home
                          </NavLink>
                        </li>
                        <li className='nav-link new_item' title='New Products' onClick={newproductToprList}>
                          <i className='fa-solid fa-bag-shopping'></i> New Products
                        </li>
                        <li className='nav-item' onClick={bestSellproductToPrList}>
                          <div className='nav-link' title='Featured Products'>
                            <i className='fa-solid fa-trophy'></i> Best Selling{' '}
                          </div>
                          {/* <p>{delivery_Slot.displayContent}</p> */}
                        </li>
                      </ul>
                    </Nav>
                  </div>
                </Container>
              </Navbar>

              {/* <div className="catey__icon">
                <a
                  className="cate__btn"
                  data-toggle="modal"
                  onClick={handleShow}
                  title="Categories"
                >
                  <i className="uil uil-apps"></i>
                </a>
              </div> */}

              <div className='header_cart order-1'>
                {/* <p>{delivery_Slot.displayContent}</p> */}
                <a onClick={showCartlist} className='cart__btn hover-btn pull-bs-canvas-left'>
                  <img src={cartImg} alt='cart-icon' />
                  <span>Cart</span>
                  <ins>{cartList.length === undefined ? 0 : cartList.length}</ins>
                  <i className='uil uil-angle-down'></i>
                </a>

                <Offcanvas show={CartCanvas} onHide={hideCartlist} placement='end' className='cart_list'>
                  <Offcanvas.Header className='bs-canvas-header side-cart-header p-3 ' closeButton>
                    <Offcanvas.Title>
                      My Cart
                      {/* ({" "}
                      {cartList.length === undefined ? 0 : cartList.length}{" "}
                      items ) */}
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Cartmodal cartList={cartList} hideCartlist={hideCartlist} deliveryCharges={deleveryChages} />
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
            </div>
          </div>
        </div>
      </header>

      <header className='header clearfix web-header'>
        <div className='anouncement'>
          {/* <marquee width='60%' direction='left'> */}
          <p>
            {delivery_Slot.displayContent}
            {/* {delivery_Slot.displayContent} */}
            {/* Daily Mandi customer care number +91 8777579395 is available from 10am to 8pm for queries feedback or any
              issues | Normal Delivery starts from 11 am */}
          </p>
          {/* </marquee> */}
        </div>
        <div className='container'>
          <div className='top-header-group'>
            <div className='top-header'>
              <div className='res_main_logo'>
                <NavLink to='/'>
                  <img src={logo} alt='logo' />
                </NavLink>
              </div>
              <div className='main_logo' id='logo'>
                <NavLink to='/'>
                  <img src={logo} alt='logo' />
                </NavLink>
                <NavLink to='/'>
                  <img className='logo-inverse' src={logo} alt='logo' />
                </NavLink>
              </div>
              <div className='select_location'>
                <div className='ui search   left icon input '>
                  <div className='pin_dropdown web-address-dropdown'>
                    <Select
                      className='w-5 srch103 greens-sel'
                      styles={customStyles}
                      options={pincode}
                      // placeholder="700157"
                      value={selectpincode.pin}
                      onChange={(e) => setSelectpincode({ ...selectpincode, pin: e })}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </div>

              <div className='search120'>
                <div className='ui search'>
                  <Form className='ui left icon input swdh10'>
                    <img src={searchIcon} alt='Search Icon' className='ser-icon' />
                    <input
                      type='search'
                      placeholder='Search for products..'
                      className='prompt srch10'
                      aria-label='Search'
                      onChange={(e) => setsValue(e.target.value)}
                      onClick={() => history.push({ pathname: '/search' })}
                    />
                  </Form>
                  {/* <p>{delivery_Slot.displayContent}</p> */}
                </div>
              </div>

              <Modal
                show={issetpincode}
                onHide={handleClose}
                className=''
                // size="lg"
                aria-labelledby='contained-modal-title-vcenter'
                centered
              >
                <Modal.Body className=''>
                  <div className='pincode-vector d-flex justify-content-center'>
                    <img src={pinVector} alt='Vector Image' className='login-pinvec' />
                  </div>
                  <div className='d-flex justify-content-center mb-2'>
                    <p className='fs-5'> Where do you want us to deliver?</p>
                  </div>
                  <div className='d-flex justify-content-center mb-2'>
                    <p className='fs-7'> Choose the pincode of your delivery</p>
                  </div>

                  <div className='d-flex justify-content-center'>
                    {/* <p>Where do you want us to deliver?</p> */}
                    {/* <div className="  "> */}

                    <div className='pin_dropdown-mdadal'>
                      <Select
                        className='w-5'
                        options={pincode}
                        // placeholder="700157"
                        value={selectpincode.pin}
                        onChange={(e) => setSelectpincode({ ...selectpincode, pin: e })}
                        isSearchable={true}
                      />

                      {/* <div >
                                                <span className='location' onClick={() => {getlocationDetails()}} ><img src={locationImage} /> My Current Location.</span>
                                            </div> */}

                      {/* <span>      <i class="bi bi-geo-alt bg-green-300 "></i></span> */}
                    </div>
                    <p>
                      {isHubid === true ? (
                        <div className='pin-val'>
                          Unfortunately, we currently do not deliver in your area. We hope to serve you soon.
                        </div>
                      ) : null}
                    </p>
                  </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
                {/*  */}
              </Modal>

              <div className='header_right'>
                <ul>
                  {/* <li>
                                     <a onClick={handleShowMessage} className="offer-link"><i className="uil uil-bell " ></i></a>
                                     <Offcanvas show={showNotification} onHide={handleCloseMessage} placement={'end'}>
                                            <Offcanvas.Header closeButton>
                                                <Offcanvas.Title>Notification </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            
                                        </Offcanvas>
                                        <a href="!#" className="offer-link"><i className="uil uil-phone-alt"></i>1800-000-000</a>
                                    </li> */}
                  {/* <li>
                    <a onClick={offersToProduct} className='offer-link'>
                      <i className='uil uil-gift'></i>Offers
                    </a>
                  </li> */}
                  {/* <li>
                                    <NavLink to='/Faq' className="offer-link"><i className="uil uil-question-circle"></i>Help</NavLink>
                                </li> */}
                  <li style={{ border: 'none' }}>
                    <a className='option_links' title='Wishlist' style={{ border: 'none' }} onClick={showWishlist}>
                      <i className='uil uil-heart icon_wishlist'></i>
                      <span className='noti_count1'>{wishList === undefined ? 0 : wishList.length}</span>
                    </a>

                    <Offcanvas show={cShow1} onHide={hideWishlist} placement={'end'}>
                      <Offcanvas.Header className='bs-canvas-header side-cart-header p-3 ' closeButton>
                        <Offcanvas.Title>Wishlist </Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <Wishlist wishlist={wishList} hideWishlist={hideWishlist} />
                      </Offcanvas.Body>
                    </Offcanvas>
                  </li>
                  {localStorage.getItem('loginpass') === 'false' || localStorage.getItem('loginpass') === null ? (
                    <li className='ui dropdown'>
                      <div
                        onClick={() => {
                          history.push('/singin');
                        }}
                        className='opts_account'
                      >
                        {/* <i className="fa fa-circle-user"></i> Log In */}
                        <img src={userIcon} alt='User Icon' className='mobile-user' />
                      </div>
                    </li>
                  ) : (
                    <li className='ui dropdown'>
                      <Dropdown>
                        <Dropdown.Toggle variant='Light' id='dropdown-basic'>
                          <div className='opts_account'>
                            {/* <img src={img2} alt="userImg" />
                                                    <span className="user__name">{user.customerName}</span>
                                                    <i className="uil uil-angle-down"></i> */}

                            {typeof userProfile.name !== 'undefined' ? (
                              <span>
                                {' '}
                                <img src={userIcon} alt='Search Icon' className='mobile-user' />{' '}
                                {/* {userProfile.name} */}
                              </span>
                            ) : (
                              <span>
                                {/* <i className='fa fa-circle-user'></i> {userProfile.phone} */}
                                <img src={userIcon} alt='Search Icon' className='mobile-user' />{' '}
                              </span>
                            )}
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <div
                              className='item channel_item'
                              onClick={() => {
                                history.push('/overview');
                              }}
                            >
                              <i className=' uil uil-user icon__1 '></i>Profile
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <div
                              className='item channel_item'
                              onClick={() => {
                                history.push('/myorder');
                              }}
                            >
                              <i className='uil uil-box icon__1'></i>Orders
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <div
                              className='item channel_item'
                              onClick={() => {
                                history.push('/mywishlist');
                              }}
                            >
                              <i className='uil uil-heart icon__1'></i>Wishlist
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <div
                              className='item channel_item'
                              onClick={() => {
                                history.push('/myaddress');
                              }}
                            >
                              <i className='uil uil-location-point icon__1'></i>
                              Address
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div
                              className='item channel_item'
                              onClick={() => {
                                history.push('/my-transaction');
                              }}
                            >
                              <i className='uil uil-wallet icon__1'></i>Wallet
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div
                              className='item channel_item'
                              onClick={() => {
                                history.push('/my-KYC');
                              }}
                            >
                              <i className='uil uil-wallet icon__1'></i>KYC Information
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item>
                            <div onClick={logOut} className='item channel_item'>
                              <i className='uil uil-lock-alt icon__1'></i>Logout
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className='sub-header-group'>
            <div className='sub-header'>
              <div className='ui dropdown'>
                <a variant='Light' className='category_drop'>
                  <button type='button' className='btn btn-green btn-md' onClick={handleShow}>
                    {' '}
                    <i className='uil uil-apps'></i>
                    <span className='cate__icon'>All Categories</span>
                  </button>
                </a>
              </div>

              <Offcanvas show={cShow} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <SelectModal handleClose={handleClose} />
                </Offcanvas.Body>
              </Offcanvas>

              <Navbar className='py-3 m-view' expand='lg'>
                <Container className='container-fluid'>
                  <div
                    id='basic-navbar-nav'
                    className='d-flex flex-column flex-lg-row flex-xl-row justify-content-lg-end bg-dark1 p-3 p-lg-0 mt1-5 mt-lg-0 mobileMenu'
                  >
                    <Nav>
                      <ul className='navbar-nav main_nav align-self-stretch'>
                        <li className='nav-item'>
                          <NavLink exact to='/' className='nav-link' title='Home' onClick={hideNav}>
                            <i className='fa-solid fa-house'></i> Home
                          </NavLink>
                        </li>
                        <li className='nav-item' onClick={newproductToprList}>
                          <NavLink exact to='/product-list-new' className='nav-link' title='Home' onClick={hideNav}>
                            <i className='fa-solid fa-bag-shopping'></i> New Product
                          </NavLink>
                        </li>
                        <li className='nav-item' onClick={bestSellproductToPrList}>
                          <NavLink exact to='/product-list-best' className='nav-link' title='Home' onClick={hideNav}>
                            <i className='fa-solid fa-bag-shopping'></i> Best Seller
                          </NavLink>
                        </li>
                        {/* <li className='nav-link' title='New Products' onClick={newproductToprList}>
                          <i className='fa-solid fa-bag-shopping'></i> New Products
                        </li> */}
                        {/* <li className='nav-item' onClick={bestSellproductToPrList}>
                          <div className='nav-link' title='Featured Products'>
                            <i className='fa-solid fa-trophy'></i> Best Seller{' '}
                          </div>
                        </li> */}
                      </ul>
                    </Nav>
                  </div>
                  {/* <p>{delivery_Slot.displayContent}</p> */}
                </Container>
              </Navbar>

              <div className='catey__icon'>
                <a className='cate__btn' data-toggle='modal' onClick={handleShow} title='Categories'>
                  <i className='uil uil-apps'></i>
                </a>
              </div>
              {/* <div>
                <p class="ribbon">
               
                <span class="rebon-text">
                <marquee width='80%' direction='left'>
                  <strong class="bold"> {delivery_Slot.displayContent}</strong>
                  </marquee>
                  </span>

                 
                </p>
               
              </div> */}
              <div className='header_cart order-1'>
                <a onClick={showCartlist} className='cart__btn hover-btn pull-bs-canvas-left'>
                  <img src={cartImg} alt='cart-icon' />
                  <span>Cart</span>
                  <ins>{cartList.length === undefined ? 0 : cartList.length}</ins>
                  <i className='uil uil-angle-down'></i>
                </a>

                <Offcanvas show={CartCanvas} onHide={hideCartlist} placement='end' className='cart_list'>
                  <Offcanvas.Header className='bs-canvas-header side-cart-header p-3 ' closeButton>
                    <Offcanvas.Title>
                      My Cart
                      {/* ({" "}
                      {cartList.length === undefined ? 0 : cartList.length}{" "}
                      items ) */}
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Cartmodal cartList={cartList} hideCartlist={hideCartlist} deliveryCharges={deleveryChages} />
                  </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas show={navToggole} onHide={hideNav} className='mmenu'>
                  <Offcanvas.Header className='mcbutt' closeButton></Offcanvas.Header>
                  <Offcanvas.Body>
                    <ul className='navbar-nav main_nav align-self-stretch'>
                      <li className='nav-item'>
                        <NavLink exact to='/' className='nav-link' title='Home' onClick={hideNav}>
                          Home
                        </NavLink>
                      </li>
                      <li className='nav-link new_item' title='New Products' onClick={newproductToprList}>
                        New Products
                      </li>
                      <li className='nav-item' onClick={bestSellproductToPrList}>
                        <div className='nav-link' title='Featured Products'>
                          Bestsellers
                        </div>
                      </li>
                    </ul>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
              <div className='search__icon order-1'>
                <a
                  href='#'
                  onClick={handleShow1}
                  className='search__btn hover-btn'
                  data-toggle='modal'
                  data-target='#search_model'
                  title='Search'
                >
                  <i className='uil uil-search'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Modal show={show1} onHide={handleClose1}>
        <Modal.Body>
          <div className='modal-header'>
            {/* <button type="button" className="close btn-close" onClick={handleClose1}>
                            <i className="uil uil-multiply"></i>
                        </button> */}
          </div>
          <div className='category-model-content modal-content'>
            <div className='search-header'>
              {/* <form onSubmit={submitSearchValue} >
                                <input type="search" placeholder="Search for products..." onChange={(e) => { setsValue(e.target.value) }} />
                                <button type="submit"><i className="uil uil-search"></i></button>
                            </form> */}
              <form>
                <input
                  type='search'
                  placeholder='Search for products...'
                  onChange={(e) => {
                    setsValue(e.target.value);
                  }}
                />
                <button type='submit'>
                  <i className='uil uil-search'></i>
                </button>
              </form>
            </div>
            {searchList.length !== 0 ? (
              <div className='search-by-cat'>
                {searchList.map((items) => (
                  <a
                    onClick={() => {
                      closeSearchModel(items);
                    }}
                    className='single-cat'
                    key={items._id}
                  >
                    <div className='icon'>
                      <img src={items.image} alt='category' />
                    </div>
                    <div className='text'>{items.name}</div>
                  </a>
                ))}
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
