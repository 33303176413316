import { createStore, applyMiddleware } from "redux";
import { loggerMiddleware } from "./middleWare";
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from "./Rootreducer";
import thunk from "redux-thunk";



const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(loggerMiddleware,thunk)
);

let preloadedCartList;

let persistedCartList = sessionStorage.getItem('cart1');
let persistedWishlist = sessionStorage.getItem('wish1');
let persistedHubdata = sessionStorage.getItem('hub_data');

if (persistedWishlist && persistedWishlist && persistedHubdata) {
    preloadedCartList = {
        cart: JSON.parse(persistedCartList),
        wish: JSON.parse(persistedWishlist),
        pin_hub:JSON.parse(persistedHubdata)
    }
}

const store = createStore(rootReducer, preloadedCartList, middlewareEnhancer);
// const store = createStore(rootReducer);

export default store;



