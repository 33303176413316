import React from "react";

const initialState = {
    data: '',

}

const Search = (search = initialState, action) => {

    switch (action.type) {
        case 'searchKeyword':
            // console.log(...action.payload)
            search.data = action.payload.data
            return { ...search }
            break;

        default:
            return search
            break;
    }
}
export default Search