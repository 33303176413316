import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import instance from '../Url';
const controller = new AbortController();
const signal = controller.signal;
// const [cookies, setCookie] = useCookies(['token', '_cid', '_wid', 'user']);

export const getCartData = (id, token) => async (dispatch, getState) => {

    try {
        await instance.get(`cart/get-cart-list/${id}`, {
            headers: {
                'Authorization': token
            }}).then(res => {
                // console.log(res);
            // dispatch({ type: 'load-cartdata', payload: res.data[0].products })
        })
    } catch (error) {
        
    }
   
}


export async function getCartlist(token , hubId) {
    // const dispatch = useDispatch();
    try {
        return   await instance.post(`customercartfavs/list/web/${hubId}`,{ "addressId": ""}, {
        
            headers: {
                'Authorization': `Bearer ${token}`
            }
        
        })
    } catch (error) {
        
    }
   
}

