import { combineReducers } from "redux";
import Cartreducer from "./CartSlice";
import Quantreducer from "./QuantitySlice";
import WishlistSlice from "./WishlistSlice";
import CartCanvasSlice from "./CartCanvasSlice";
import globalVariablesReducer from "./GlobalSlice";
import pinSlice from "./pinSlice";
import cartItemQuentity from "./cartitemIncrease&DecreaseSlice";
import UserReducer from "./userSlice";
import pin_hub_data from "./pincode_hub";
 import WishlistAdd from "./addwishlist";
 import CartItems from "./cartitem";
//  import wishlistState from "./wishliststate";
import RequirereduxState from "./wishliststate";
import cartState from "./cartstate";
import Search from "./seearch";
import deliverySlot from "./deliveryslot";
const rootReducer = combineReducers({
    cart: Cartreducer,
    quantity: Quantreducer,
    wish: WishlistSlice,
    CartCav: CartCanvasSlice,
    global: globalVariablesReducer,
    Pin: pinSlice,
    cartquen: cartItemQuentity,
    userProfile: UserReducer,
    pin_hub: pin_hub_data,
    addwishlist: WishlistAdd,
    Cartitemlist:CartItems,
    wishlistState : RequirereduxState,
    cartState :cartState,
    search:Search,
    deliverySlot:deliverySlot

})

export default rootReducer;