const initialState=[]

const WishlistAdd =(wishlist=initialState,action)=>{
    switch(action.type){
        case 'wish-product-added' : {
            // console.log(action.payload)
            
            return [
                ...wishlist,
                {
                    ...action.payload,
                    
                }
            ]
        }
        
        default:
            return wishlist

    }
}

export default WishlistAdd;