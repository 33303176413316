import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import instance from './Url';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import { getCartData } from './Redux/cart.action';
import { getTax } from './Redux/global.action';
import 'bootstrap/dist/css/bootstrap.min.css';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SelectModal from './Selectmodal';

// import { ToastContainer, toast } from 'react-toastify';
import img1 from '../images/footer-icons/pyicon-6.svg';
import img2 from '../images/footer-icons/pyicon-1.svg';
import img3 from '../images/footer-icons/pyicon-2.svg';
import img4 from '../images/footer-icons/pyicon-3.svg';
import img5 from '../images/footer-icons/pyicon-4.svg';
import backtotop from './assets/back-to-top.png';
import fc1 from './assets/fc-1.png';
import fc2 from './assets/fc-2.png';
import fc3 from './assets/fc-3.png';
import fc4 from './assets/fc-4.png';
import logo from '../images/footer-logo.png';
import plastore from './assets/plastore.jpg';
import apple from './assets/apple.jpg';
import menu1 from './assets/house 1.png';
import menu2 from './assets/share-icon.png';
import menu3 from './assets/menu 3.png';
import menu4 from './assets/icons8-file-64.png';
import menu5 from './assets/menu 1.png';
import wpIcon from '../images/whatsapp-wh.png';
import phIcon from '../images/phone2.png';
import emIcon from '../images/email.png';
const Footer = () => {
  const dispatch = useDispatch();
  const [cShow, setcShow] = useState(false);
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['token', '_cid', '_wid']);

  const offersToProduct = () => {
    history.push({
      pathname: '/product-list',
      state: {
        productType: 'Offers',
        name: 'Offers-Product for you',
      },
    });
  };

  const getCategory = () => {
    instance.get('category/get-category-parent').then((res) => {
      let arr = res.data.data;
      arr = arr.map((el) => {
        el['selected'] = false;
        return el;
      });
      dispatch({ type: 'category-for-filter', payload: arr });
    });
  };

  useEffect(() => {
    dispatch(getTax());
    // console.log(window.location.pathname)
  }, []);

  const singInPage = () => {
    // console.log('loginWorks');
    let user = cookies.token;

    if (user == undefined) {
      history.push('/singin');
    } else {
      toast.error('Your are already loged-In', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const ToOrderpage = () => {
    let user = cookies.token;
    if (user === undefined) {
      history.push('/singin');
    } else {
      history.push('/myorder');
    }
  };
  const handleClose = () => {
    setcShow(false);
  };
  const handleShow = () => setcShow(true);

  function openCategory() {
    history.push('/categoryview');
  }

  const bestSellproductToPrList = () => {
    // hideNav()
    history.push({
      pathname: '/product-list',
      state: {
        productType: 'best',
        name: 'Best-Selling-Products',
      },
    });
  };

  const newproductToprList = () => {
    // hideNav()
    history.push({
      pathname: '/product-list',
      state: {
        productType: 'new',
        name: 'New-Added-Products',
      },
    });
  };

  function activeMenu() {
    let menuitemMain = document.getElementById('footer-bar');
    let menuLink = menuitemMain.getElementsByClassName('menu_item');

    for (var i = 0; i < menuLink.length; i++) {
      menuLink[i].addEventListener('click', function () {
        var current = document.getElementsByClassName('active-nav');
        current[0].className = current[0].className.replace('active-nav', '');
        this.className += 'active-nav';
        // menuLink[i].classList.add('')
      });
    }
  }

  function gotoCategory() {
    history.push('/categoryview');
  }

  function gotoMenu() {
    history.push('/menu');
  }

  function offerPage() {
    history.push({
      pathname: '/referal',
      // state: {
      //   productType: 'best',
      //   name: 'Best-Selling-Products',
      // },
    });
  }

  return (
    <>
      <div className='container'>
        <div className='footer_one mob-hide'>
          <div className='footer-box'>
            <div className='icon'>
              <i className='fa fa-truck'></i>
            </div>
            <ul>
              <li className='line1'>Low Shipping</li>
              <li className='line2'>Low Shipping</li>
            </ul>
          </div>
          <div className='footer-box'>
            <div className='icon'>
              <i className='fa fa-phone'></i>
            </div>
            <ul>
              <li className='line1'>Helpline</li>
              <li className='line2'>(+91) 9007170780</li>
            </ul>
          </div>
          <div className='footer-box'>
            <div className='icon'>
              <i className='fa fa-headphones'></i>
            </div>
            <ul>
              <li className='line1'>24x7 Support</li>
              <li className='line2'>Free For Customers</li>
            </ul>
          </div>
          <div className='footer-box'>
            <div className='icon'>
              <i className='fa fa-exchange'></i>
            </div>
            <ul>
              <li className='line1'>Returns</li>
              <li className='line2'>3 Days Exchanges</li>
            </ul>
          </div>
        </div>
      </div>

      <div className='footer_two mob-hide'>
        <div className='container'>
          <div className=''>
            <div className='row'>
              <div className='col-3'>
                <a href='/'>
                  <img src={logo} alt='service image' className='fl' />
                </a>
                <ul className='footer-menu-items'>
                  <li>Uniretailkart Delivery Services Private Limited</li>
                  <li>
                    <i className='fa fa-map-marker'></i> 5A, Ishwar Mondal lane, Kolkata 700046.
                  </li>
                </ul>
              </div>
              <div className='col'>
                <div className='footer-block__heading'>Useful Links</div>
                <ul className='footer-menu-items'>
                  <li>
                    <NavLink to='/AboutUs'>About Us</NavLink>
                  </li>

                  <li>
                    <NavLink to='/contactus'>Contact Us</NavLink>
                  </li>
                  <li>
                    <NavLink to='/terms&condition'> Term & Conditions</NavLink>{' '}
                  </li>
                  <li>
                    <NavLink to='/intellectual'> Intellectual Property Disclaimer</NavLink>{' '}
                  </li>
                </ul>
              </div>
              <div className='col'>
                <div className='footer-block__heading'>Legal</div>
                <ul className='footer-menu-items'>
                  <li>
                    <NavLink to='/privacy-policy'>Privacy Policy</NavLink>{' '}
                  </li>
                  <li>
                    <NavLink to='/return-and-cancel-policy'> Return and Cancel Policy</NavLink>{' '}
                  </li>
                  <li>
                    <NavLink to='/refund'> Refund Policy</NavLink>{' '}
                  </li>
                  <li>
                    <NavLink to='/shipping'> Shipping Policy</NavLink>{' '}
                  </li>
                  <li>
                    <NavLink to='/payment'> Payment and Logistics Partners</NavLink>{' '}
                  </li>
                </ul>
              </div>
              <div className='col'>
                <div className='footer-block__heading'>Contact Us</div>
                <ul className='footer-menu-items'>
                  <li>
                    {''}
                    <i className='fa fa-phone'></i> <a href='tel:+919007170780'>+91 9007170780</a>
                  </li>
                  <li>
                    {' '}
                    <img src={wpIcon} alt='service image' />{' '}
                    <a href='https://wa.me/919007170780?text=Hi' target='_blank' rel='noreferrer'>
                      +91 9007170780
                    </a>
                  </li>
                  <li>
                    {' '}
                    <i className='fa fa-envelope'></i>{' '}
                    <a href='mailto:writetodailymandi@gmail.com'>writetodailymandi@gmail.com </a>
                  </li>
                </ul>
              </div>

              {localStorage.getItem('loginpass') === 'true' ? (
                <div className='col'>
                  <div className='footer-block__heading'>Account</div>
                  <ul className='footer-menu-items'>
                    <li>
                      <NavLink to='/mywishlist'>Wishlist</NavLink>
                    </li>
                    <li>
                      <NavLink to='/myorder'>Orders</NavLink>
                    </li>
                    <li>
                      <NavLink to='/myaddress'>Address</NavLink>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
          <div className='footer-three'>
            <p className='text-center'>© 2023 Uniretailkart Delivery-Services Private Limited. All Rights Reserved.</p>
          </div>
        </div>
      </div>
      {/* ======================================================= */}
      <div id='footer-bar' className='footer-bar-6'>
        <NavLink exact to='/' className='active-nav'>
          <img src={menu1} alt='' className='menu-icon' />
          <span>Home</span>
          <em></em>
          <strong>
            <u></u>
          </strong>
        </NavLink>
        <NavLink exact to='/categoryview' className='active-nav'>
          <img src={menu5} alt='' className='menu-icon' />
          <span>Category</span>
          <em></em>
        </NavLink>
        <NavLink exact to='/referal' className='active-nav'>
          <img src={menu2} alt='' className='menu-icon' />
          {/* <li className='uil uil-share'></li> */}
          <span>Share</span>
          <em></em>
        </NavLink>

        <NavLink
          exact
          to={localStorage.getItem('loginpass') === 'true' ? '/myorder' : '/singin'}
          className='active-nav'
        >
          <img src={menu3} alt='' className='menu-icon' />
          <span>Order</span>
          <em></em>
        </NavLink>
        <NavLink exact to='/menu' className='active-nav'>
          <img src={menu4} alt='' className='menu-icon' />
          <span>Menu</span>
          <em></em>
        </NavLink>
      </div>

      <Offcanvas show={cShow} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Categories</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SelectModal handleClose={handleClose} />
        </Offcanvas.Body>
      </Offcanvas>

      <ToastContainer />

      <a onClick={() => window.scrollTo(0, 0)} className='back-to-top'>
        <img src={backtotop} alt='back' style={{ width: '37px' }} />
      </a>
    </>
  );
};
export default Footer;
