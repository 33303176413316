let cartItems = [];

function CartItems(initialcartitems = cartItems, action) {

    switch (action.type) {
        case 'added-to-cart':
            return [
                ...initialcartitems, {
                    ...action.payload
                }
            ]

            default :
            return initialcartitems;

    }

}

export default CartItems;