import instance from "../Url"

export async function getWishlist(token , hub_id) {
try {
    return await instance.get(`customercartfavs/wishlist/web/${hub_id}`, {

        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
} catch (error) {
    
}
    
}


export async function addToWishList(data, token) {

    return await instance.post('customercartfavs/wishlist/web/', data, {

        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}


export async function deletewishList(token, id) {
    return await instance.delete(`customercartfavs/wishlist/web/${id}`, {

        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
}