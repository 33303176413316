const InitialState=false;

const cartItemQuentity=(state=InitialState,action)=>{
    switch(action.type){
        case 'icrease-ietm':{
            return state= true;
        }

        case 'decrease-item':{
            return state = true
        }
        default:
            return state;
    }
};
export default cartItemQuentity;