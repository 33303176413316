export default function UserReducer(initialState = {
    image: '',
    name: '',
    email: '',
    phone: '',
    dob: '',
    age: '',
    defaultAddress: '',
    defaultLandmark: '',
    defaultPincode: 0
}, action) {

    switch (action.type) {

        case 'after-login-profile': {
            // console.log(action.payload)
            let obj =  { ...initialState, phone: action.payload }
            console.log(obj)
            return obj
            // console.log(obj)
            // let {phone} = action.payload
            // return initialState.phone = action.payload
        }
        default:

            return initialState
    }

}
