const InitialState=false;

const CartCanvasSlice=(state=InitialState,action)=>{
    switch(action.type){
        case 'open-canvas':{
            return state= true;
        }

        case 'close-canvas':{
            return state=false;
        }

        case 'incres-cart-item':{
            return state = true
        }

        case 'remove_wish_item' :{
         return state = false
        }
        default:
            return state;
    }
};
export default CartCanvasSlice;